const message = {
  select: "sélectionner",
  team: "équipe",
  submit: "soumettre",
  collaborator: "collaborateur",
  create: "créer",
  site: "site",
  categories: "catégories",
  download: "télécharger",
  totalHours: "heures totales",
  gived_polyvalence: "polyvalence donnée",
  received_polyvalence: "polyvalence reçue",
  polyvalence: "polyvalence",
  total: "total",
  edit: "modifier",
  remove: "supprimer",
  date: "date",
  restore: "restaurer",
  areYouSure: "Êtes-vous sûr ?",
  restoreProcess: "vous êtes sur le point de restaurer un processus supprimé!",
  yes_i_understand: "Oui, je comprends",
  cancel: "annuler",
  showDeletedProcesses: "afficher les processus supprimés",
  update: "mise à jour",
  restore_description: "vous êtes sur le point de restaurer un processus supprimé",
  deleteDescription: "vous êtes sur le point de supprimer un processus",
  requiredFieldsAreMissing: "les champs obligatoires sont manquants",
  errNetwork: "service indisponible",
  err400: "données invalides",
  err403: "interdit",
  err404: "page introuvable",
  err409: "des données existent déjà",
  err500: "erreur de service",
  err501: "serveur non disponible",
  "Site not Found": "site introuvable",
  pending_save: "en attente de sauvegarde",
  hours_pending_save_description: "vous êtes sur le point de perdre les heures déclarées.",
  save: "enregistrer",
  ignore: "ignorer",
  destroyed_hours: "heures détruites",
  recovered_hours: "heures récupérées",
  saved_hours: "heures économisées",
  recovered_process: "processus récupéré",
  delete_category_description: "vous êtes sur le point de supprimer une catégorie",
  delete_site_description: "vous êtes sur le point de supprimer un site",
  deleted_category: "la catégorie est supprimée",
  deleted_site: "le site est supprimé",
  required_field: "champ obligatoire",
  required_field_category_description: "le champ de description est obligatoire",
  required_field_category_name: "le champ de nom est obligatoire",
  required_field_fedid_site_code: "le code du site fedid est requis",
  required_field_site_name: "le nom du site est obligatoire",
  required_field_site_code: "le code du site est obligatoire",
  created_category: "la catégorie est créée",
  created_site: "le site est créé",
  "Activity duration exceding 9 Hours.": "Durée de l'activité supérieure à 9 heures",
  change_your_site: "changez votre site",
  change_your_team: "changez votre équipe",
  change_your_email: "changez votre email",
  have_api_planning: "avoir une planification API",
  table_is_empty: "la table est vide",
  add_to_drive: "ajouter à mon Google Drive",
  configuration: "config",
  economic: "économique",
  explotation: "exploitation",
  account: "compte",
  hda: "hda",
  "Total Hours of whole activities is more than 9h.": "le total des heures d'activités entières est supérieur à 9h.",
  "bad request": "mauvaise demande",
  "page not found": "page introuvable",
  select_date: "sélectionner la date",
  select_week: "sélectionner la semaine",
  select_team: "sélectionner l'équipe",
  select_collaborator: "sélectionner un collaborateur",
  date_from: "de...",
  date_to: "a...",
  more_options: "plus d'options",
  close_dialog: "fermer la boîte de dialogue",
  where_you_have_worked: "où vous avez travaillé",
  when_you_have_worked: "quand tu auras travaillé",
  the_week_what_do_you_search: "la semaine, que cherches-tu",
  the_team_what_do_you_search: "l'équipe, que cherches-tu",
  the_collaborator_what_do_you_search: "le collaborateur, que cherches-tu",
  site_name: "nom du site",
  site_code: "code du site",
  fedid_site_code_openid: "code du site fedid openId",
  fedid_site_code: "code du site fedid",
  site_code_planning: "planification du centre de code du site",
  category_name: "nom de la catégorie",
  category_description: "description de la catégorie",
  user_title: "nom d'utilisateur",
  total_hours_title: "total",
  total_hours_provider_title: "planification totale",
  difference_hours_title: "différence",
  hours_in_team_title: "heures en équipe",
  given_polyvalence_title: "polyvalence donnée",
  received_polyvalence_title: "polyvalence reçue",
  process: "processus",
  polyvalence_team: "équipe polycalence",
  hours: "heures",
  date_title: "date",
  name: "prenom",
  planning_code: "code du site dans planning",
  fedid_code: "code du site dans fedid",
  description: "description",
  reset_form: "reset form",
  provider_api: "is connected with planning",
  no: "non",
  yes: "oui",
  your_email_does_not_exist_in_the_app: "your profile does not existe in the app",
  we_can_create_a_fictitious_profile_in_a_fictitious_center:
    "you can create a foctitious profile in a fictitious center",
  you_are_about_to_create_a_fictitious_profile_in_a_fictitious_center:
    "you are about to create a fictitious profile in a fictitious center",
  the_value_should_between_0_and_9: "the quantity of hours should between 0h and 9h",
  excel: "excel",
  no_content: "there isn't content",
  "logistic process": "logistic process",
  total_hours: "total hours",
  planning: "planning",
  logistic_process: "process",
  dates_error: "dates error",
  date_from_is_later_than_date_to: "the initial date is later than end date",
  username: "username",
  dni: "id",
  uuid: "uuid",
  sap_number: "sap number",
  email: "email",
  select_site: "select a site",
  new_collaborator: "new collaborator",
  all_fields_are_required: "all fields are required",
  field_are_required: "field are required",
  sap_structure_number: "sap structure number",
  delete_team_description: "you are about to delete a team",
  "deleted/notDeleted": "deleted or not deleted",
  total_hda: "total hda",
  total_team: "total team",
  ask_support: "ticket soporte it",
  manual: "manually",
  is_manual_user: "is a manually user",
  sites: "sites",
  sites_where_has_access: "sites where has access",
  select_multi_vada: "select multi vada",
  change_site: "change to an other site",
};

export default message;
