import { createI18n } from 'vue-i18n'
import ca from '../translation/ca'
import en from '../translation/en'
import es from '../translation/es'
import fr from '../translation/fr'
import pt from '../translation/pt'
import tk from '../translation/tk'

const messages = {
    EN:en,
    ES:es,
    FR:fr,
    CA:ca,
    PT:pt,
    TK:tk
}

const i18nConfig = createI18n({
    locale: 'ES',
    legacy: false,
    globalInjection: true,
    fallbackLocale: 'ES',
    messages
})


export default i18nConfig;