import { AxiosResponse } from "axios";
import { IAuthService } from "../interfaces/AuthService";
import { IRefreshToken } from "../interfaces/IRefreshToken";
import { IToken } from "../interfaces/IToken";
import hdaRest from "@/modules/hdaModule/rest/hdaRest";
import authRest from "../api/authRest";

export class AuthService implements IAuthService {

    private path = '/v1/auth';
    
    getToken(code: string): Promise<AxiosResponse<IToken>> {
        return authRest.get<IToken>(`${this.path}/token/generate?code=${code}`);
    }
    refreshToken(refreshToken: string): Promise<AxiosResponse<IRefreshToken>> {
        return authRest.get<IRefreshToken>(`${this.path}/token/refresh?refresh_token=${refreshToken}`);
    }
    revokeSession(piSri: string): Promise<AxiosResponse<{id:string}>> {
        return hdaRest.get<{id:string}>(`${this.path}/revoke_session?pi_sri=${piSri}`);
    }
}