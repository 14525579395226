import { AxiosResponse } from 'axios';
import useAuth from './useAuth';
import { AuthService } from '../services/AuthService';
import { IToken } from '../interfaces/IToken';
import { IRefreshToken } from '../interfaces/IRefreshToken';

const useFedid = () => {
    const redirectFedid = (): Promise<AxiosResponse<IToken>> | undefined => {
        const { setAfterLogin } = useAuth();
        const url = new URL(window.location.href);
        const code = url.searchParams.get('code');

        if (!code) {
            setAfterLogin(url.pathname);
            window.location.href = `${process.env.VUE_APP_FEDID_API_URL}${process.env.VUE_APP_AUTH_AUTORIZATION}?response_type=code&client_id=${process.env.VUE_APP_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_REDIRECT_URI}&scope=openid%20profile%20email`
            return;
        }
        return getToken(code);
    }

    const getToken = async (code: string): Promise<AxiosResponse<IToken>> => {

        const authService = new AuthService();
        return authService.getToken(code);
    }

    const refreshToken = (refreshToken:string): Promise<AxiosResponse<IRefreshToken>> => {
        const authService =  new AuthService();
        return authService.refreshToken(refreshToken);
    }

    const revokeFed = async (sri: string):Promise<AxiosResponse<{id:string}>> => {

        const authService = new AuthService();
        return authService.revokeSession(sri);
    }

    return {
        redirectFedid,
        onRefreshToken: refreshToken,
        revokeFed
    }
}

export default useFedid;