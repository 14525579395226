export default {
  name: "PlanningLayout",
  path: "/planning",
  component: () => import(/* webpackChunkName: "PlanningLayout" */ "../layouts/PlanningLayout.vue"),
  children: [
    {
      path: "/planning/:id",
      name: "planning",
      component: () => import(/* webpackChunkName: "planning" */ "../views/PlanningView.vue"),
    },
  ],
};
