import { defineStore } from "pinia";
import { Data } from "../interfaces/ResponseUserInfo";
import { AuthStoreInterface } from "../interfaces/AuthStoreState";

export const useAuthStore = defineStore('authStore', {
    state: (): AuthStoreInterface => ({
        accessToken: undefined,
        initDate: undefined,
        lastTokenDate: undefined,
        loginState: false,
        redirectAfterLogin: undefined,
        refreshToken: undefined,
        userInfo: undefined,
    }),
    getters: {
        getRoles(): ({ role: string, app: string }[] | undefined) {
            return this.userInfo?.role.map(roleInfo => {
                const [role, app] = roleInfo.split(',');
                return {
                    role: role.replace('id=', ''),
                    app: app.replace('id=', '')
                }
            })
        },
        getRolesByAppName() {
            return (appName: string) => {
                return this.getRoles?.filter(role => role.app === appName);
            }
        },
        isTranscurredRefreshTime(): boolean {
            const currentDate = new Date();
            if (!this.initDate) return false;
            return (currentDate.getTime() - new Date(this.initDate).getTime()) / 1000 / 60 > process.env.VUE_APP_REFRESH_TOKEN_TIME;
        }
    },
    actions: {
        setAfterLogin(path: string) {
            this.redirectAfterLogin = path;
        },
        setLoginState(loginState: boolean) {
            this.loginState = loginState;
        },
        setAccessToken(accessToken: string) {
            this.accessToken = accessToken;
        },
        setRefreshToken(refreshToken: string) {
            this.refreshToken = refreshToken;
        },
        setInitDate() {
            this.initDate = new Date();
        },
        setLastTokenDate() {
            this.lastTokenDate = new Date();
        },
        setUserInfo(userInfo: Data) {
            this.userInfo = {
                displayName: userInfo.displayName,
                jobname: userInfo.jobname,
                mail: userInfo.mail,
                ["pi.sri"]: userInfo["pi.sri"],
                preferredLanguage: userInfo.preferredLanguage,
                role: userInfo.role,
                site: userInfo.site,
                uuid: userInfo.uuid
            }
        },
        updateSite(newSiteCode: string) {
            if (this.userInfo) {
                this.userInfo.site = newSiteCode;
            }
        },
        updateEmail(newEmail: string) {
            if (this.userInfo) {
                this.userInfo.mail = newEmail;
            }
        },
        updateUuid(newUuid: string){
            if(this.userInfo){
                this.userInfo.uuid = newUuid;
            }
        },
        revokeSession() {
            this.loginState = false;
            this.accessToken = undefined;
            this.refreshToken = undefined;
            this.userInfo = undefined;
            this.initDate = undefined;
            this.redirectAfterLogin = undefined;
            this.lastTokenDate = undefined;
        }
    },
    persist: true
})