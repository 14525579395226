const message = {
  select: "select",
  team: "team",
  submit: "submit",
  collaborator: "collaborator",
  create: "create",
  site: "site",
  categories: "categories",
  download: "download",
  totalHours: "total hours",
  received_polyvalence: "received polyvalence",
  polyvalence: "polyvalence",
  total: "total",
  edit: "edit",
  remove: "remove",
  date: "date",
  restore: "restore",
  areYouSure: "Are you sure?",
  restoreProcess: "you are about to restore a deleted process",
  yes_i_understand: "Yes, I understand",
  cancel: "cancel",
  showDeletedProcesses: "show deleted processes",
  update: "update",
  restore_description: "you are about to restore a deleted process",
  deleteDescription: "you are about to delete a process",
  requiredFieldsAreMissing: "required fields are missing",
  errNetwork: "service unavailable",
  err400: "invalid data",
  err403: "forbidden",
  err404: "not found page",
  err409: "data already exist ",
  err500: "service error",
  err501: "server not available",
  "Site not Found": "site not Found",
  pending_save: "pending to save",
  hours_pending_save_description: "you are about to lose the declared hours",
  save: "save",
  ignore: "ignore",
  destroyed_hours: "destroyed hours",
  recovered_hours: "recovered hours",
  saved_hours: "saved hours",
  recovered_process: "recovered process",
  delete_category_description: "you are about to delete a category",
  delete_site_description: "you are about to delete a site",
  deleted_category: "the category is deleted",
  deleted_site: "the site is deleted",
  required_field: "required field",
  required_field_category_description: "the description field is required",
  required_field_category_name: "the name field is required",
  required_field_fedid_site_code: "the fedid site code is required",
  required_field_site_name: "the site name is required",
  required_field_site_code: "the site code is required",
  created_category: "the category is created",
  created_site: "the site is created",
  "Activity duration exceding 9 Hours.": "Activity duration exceding 9 Hours",
  change_your_site: "change your site",
  change_your_team: "change your team",
  change_your_email: "change your email",
  have_api_planning: "have api planning",
  table_is_empty: "the table is empty",
  add_to_drive: "add to my google drive",
  configuration: "config",
  economic: "economic",
  explotation: "explotation",
  account: "account",
  hda: "hda",
  "Total Hours of whole activities is more than 9h.": "total Hours of whole activities is more than 9h.",
  "bad request": "bad request",
  "page not found": "page not found",
  select_date: "select date",
  select_week: "select week",
  select_team: "select team",
  select_collaborator: "select collaborator",
  date_from: "from...",
  date_to: "to...",
  more_options: "more options",
  close_dialog: "close dialog",
  where_you_have_worked: "where you have worked",
  when_you_have_worked: "when you have worked",
  the_week_what_do_you_search: "the week what do you search",
  the_team_what_do_you_search: "the team what do you search",
  the_collaborator_what_do_you_search: "the collaborator what do you search",
  site_name: "site name",
  site_code: "site code",
  fedid_site_code_openid: "fedid site code openId",
  fedid_site_code: "fedid site code",
  site_code_planning: "site code center planning",
  category_name: "category name",
  category_description: "category description",
  user_title: "user name",
  total_hours_title: "total",
  total_hours_provider_title: "total planning",
  difference_hours_title: "difference",
  hours_in_team_title: "hours in team",
  given_polyvalence_title: "given polyvalence",
  received_polyvalence_title: "received polyvalence",
  process: "process",
  polyvalence_team: "polyvalence team",
  hours: "hours",
  date_title: "date",
  planning_code: "planning code center",
  fedid_code: "fedid code center",
  description: "description",
  reset_form: "reset form",
  provider_api: "is connected with planning",
  no: "no",
  yes: "yes",
  your_email_does_not_exist_in_the_app: "your profile does not existe in the app",
  we_can_create_a_fictitious_profile_in_a_fictitious_center:
    "you can create a foctitious profile in a fictitious center",
  you_are_about_to_create_a_fictitious_profile_in_a_fictitious_center:
    "you are about to create a fictitious profile in a fictitious center",
  the_value_should_between_0_and_9: "the quantity of hours should between 0h and 9h",
  excel: "excel",
  no_content: "there isn't content",
  "logistic process": "logistic process",
  total_hours: "total hours",
  planning: "planning",
  logistic_process: "process",
  dates_error: "dates error",
  date_from_is_later_than_date_to: "the initial date is later than end date",
  username: "username",
  dni: "id",
  uuid: "uuid",
  sap_number: "sap number",
  email: "email",
  select_site: "select a site",
  new_collaborator: "new collaborator",
  all_fields_are_required: "all fields are required",
  field_are_required: "field are required",
  sap_structure_number: "sap structure number",
  delete_team_description: "you are about to delete a team",
  "deleted/notDeleted": "deleted or not deleted",
  total_hda: "total hda",
  total_team: "total team",
  ask_support: "ticket soporte it",
  manual: "manually",
  is_manual_user: "is a manually user",
  sites: "sites",
  sites_where_has_access: "sites where has access",
  select_multi_vada: "select multi vada",
  change_site: "change to an other site",
};

export default message;
