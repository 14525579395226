import Swal, { SweetAlertResult } from "sweetalert2";
import { TypeSwalIcons } from "./TypeSwalIcons";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ConfirmSwal = (
  title: string,
  text: string,
  icon: TypeSwalIcons,
  confirmButtonText: string,
  cancelButtonText: string,
  denyButtonText?: string,
  html?: string
): Promise<SweetAlertResult<unknown>> => {
  let showDenyButton = false;
  if (denyButtonText) {
    showDenyButton = true;
  }

  return Swal.fire({
    title,
    text,
    icon,
    showCancelButton: true,
    showDenyButton,
    confirmButtonColor: "var(--vtmn-color_brand-digital)",
    cancelButtonColor: "var(--vtmn-color_grey",
    confirmButtonText,
    cancelButtonText,
    denyButtonText,
    denyButtonColor: "var(--vtmn-color_black)",
    html,
  });
};
