import axios from "axios";
import useAuth from "@/modules/oauth/composables/useAuth";
import { ToastMixin } from "@/tools/ToastMixin";
import applyCaseMiddleware from "axios-case-converter";
import checkErrorsApiResponse from "@/tools/checkErrorsApiResponse";

const toast = ToastMixin('error');

const hdaRest = applyCaseMiddleware(axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": process.env.VUE_APP_API_KEY
    }
}));

hdaRest.interceptors.request.use((config) => {
    const { accessToken } = useAuth();
    config.headers.Authorization = "Bearer " + accessToken.value;
    return config;
}, (error) => {
    return Promise.reject(error);
});

hdaRest.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const errorData = checkErrorsApiResponse(error);
    if (errorData.icon != "success") {
        if (!errorData.text) {
            await toast.fire({
                icon: errorData.icon,
                title: errorData.title
            })
        } else {
            await toast.fire({
                icon: errorData.icon,
                title: errorData.title,
                text: errorData.text
            })
        }
    }

    return Promise.reject(error);
});

export default hdaRest;