import { storeToRefs } from "pinia";
import { useHdaStore } from "../store/useHdaStore";
import { useSuperAdminStore } from "../store/useSuperAdminStore";

const useHda = () => {
  const store = useHdaStore();
  const superAdminSuperAdmin = useSuperAdminStore();

  const {
    processData,
    getProcessDataByDate,
    getProcessDataByProcessId,
    getProcessDataByTeamId,
    getProcessDataByUserId,
    isLoading,
    currentWeek,
    thereAreChanges,
    isSimpleLoading,
  } = storeToRefs(store);

  const { superAdminTeamId, sites } = storeToRefs(superAdminSuperAdmin);

  return {
    currentWeek,
    getProcessDataByDate,
    getProcessDataByProcessId,
    getProcessDataByTeamId,
    getProcessDataByUserId,
    isLoading,
    processData,
    superAdminTeamId,
    thereAreChanges,
    sites,
    isSimpleLoading,

    addProcessData: store.addProcessData,
    getCurrentWeek: store.getWeek,
    getDeclaredHours: store.getDeclaredHours,
    getDeclaredHoursByProcessIdAndDate: store.getDeclaredHoursByProcessIdAndDate,
    loading: store.loading,
    resetProcessData: store.resetProcessData,
    updateCurrentWeek: store.setCurrentWeek,
    updateSuperAdminTeamId: superAdminSuperAdmin.updateSuperAdminTeamId,
    setChanges: store.setChanges,
    setSites: superAdminSuperAdmin.setSites,
    simpleLoading: store.simpleLoading,
  };
};

export default useHda;
