import swal from 'sweetalert2';
import { TypeSwalIcons } from './TypeSwalIcons';
export const ToastMixin = (action: TypeSwalIcons): typeof swal => {

    const colors = {
        success: {
            background: 'var(--vtmn-color_success)',
            color: 'white'
        },
        error: {
            background: 'var(--vtmn-color_danger)',
            color: 'white'
        },
        warning: {
            background: 'var(--vtmn-color_warning)',
            color: 'white'
        },
        info: {
            background: 'var(--vtmn-color_info)',
            color: 'black'
        },
        question: {
            background: 'var(--vtmn-color_grey)',
            color: 'white'
        }
    }

    return swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: 'white',
        customClass: {
            popup: 'colored-toast'
        },
        background: colors[action].background,
        color: colors[action].color,
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true
    })
}