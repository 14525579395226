import { defineStore } from "pinia";

export const useSuperAdminStore = defineStore('superAdminStore', {
    state: ():{superAdminTeamId:number,sites:{siteCode:string}[]} => ({
        superAdminTeamId: 0,
        sites:[]
    }),
    getters: {
        
    },
    actions: {
        updateSuperAdminTeamId(id:number){
            this.superAdminTeamId = id;
        },
        setSites(value:{siteCode:string}[]){
            this.sites = value
        }
    },
    persist: true
})