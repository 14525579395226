import { useRouter } from "vue-router";
import useAuth from "../composables/useAuth";
import useFedid from '../composables/useFedid';
import useLanguage from "@/modules/language/composables/useLanguage";
import i18nConfig from "@/modules/language/configuration/i18nConfig";
import { Data, LanguageTypes } from "../interfaces/ResponseUserInfo";
import { AxiosError } from "axios";
import { jwtDecode } from "jwt-decode";

const fedidService = {
    refreshTokenAndSaveData: async (checkLastDate: boolean): Promise<void> => {
        const {
            isTranscurredRefreshTime,
            setAccessToken,
            setInitDate,
            setRefreshToken,
            revokeSession,
            refreshToken
        } = useAuth();

        const { onRefreshToken } = useFedid();

        if ((isTranscurredRefreshTime && checkLastDate) || !checkLastDate) {

            if(!refreshToken.value){
                throw new Error('refresh token undefined');
            }
            const { data, status } = await onRefreshToken(refreshToken.value);
            
            if ( status === 200) {
                setInitDate();
                setAccessToken(data.accessToken);
                setRefreshToken(data.refreshToken);
            } else {
                revokeSession()
            }
        }
    },
    getTokenAndSaveData: async (): Promise<void> => {
        const router = useRouter();
        const {
            loginState,
            redirectAfterLogin,
            setAccessToken,
            setInitDate,
            setLastTokenDate,
            setLoginState,
            setRefreshToken,
            setUserInfo,
        } = useAuth();

        const { redirectFedid } = useFedid();
        const { changeLanguage } = useLanguage()

        if (!loginState.value) {
            
            const response = await redirectFedid();
            if (!response) return;
            const { data, status } = response;
            if (!redirectAfterLogin.value || status !== 200) return;

            const userInfo: Data = jwtDecode(data.idToken);
            setUserInfo(userInfo);
            setLoginState(true);
            setInitDate();
            setLastTokenDate();
            setAccessToken(data.accessToken);
            setRefreshToken(data.refreshToken);

            const language = userInfo.preferredLanguage as LanguageTypes;
            changeLanguage(language);
            i18nConfig.global.locale.value = language;

            router.replace({ path: redirectAfterLogin.value });

        }
    },
    revokeSession: (): void => {
        const { lastTokenDate, revokeSession } = useAuth();
        if (!lastTokenDate.value) return;
        if ((new Date().getTime() - new Date(lastTokenDate.value).getTime()) / 1000 / 60 > process.env.VUE_APP_LOGIN_REVOKED_TIME) {
            revokeSession();
            window.location.reload();
        }
    },
    logout: () => {
        const { revokeSession, userInfo } = useAuth();
        const { revokeFed } = useFedid();
        if (!userInfo.value) return;
        revokeFed(userInfo.value["pi.sri"].split(".")[0]).then(() => {
            revokeSession();
            window.location.reload();
        }).catch((error: AxiosError) => {
            throw new Error(error.message);
        })
    }
}

export default fedidService;