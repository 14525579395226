const message = {
  select: "selecciona",
  team: "equipo",
  submit: "Enviar",
  collaborator: "colaborador",
  create: "crear",
  site: "centro",
  categories: "categorias",
  download: "descargar",
  totalHours: "total horas",
  polyvalence: "polivalencia",
  total: "total",
  edit: "editar",
  remove: "eliminar",
  date: "fecha",
  restore: "restaurar",
  areYouSure: "¿estás seguro?",
  restoreProcess: "estás a punto de restaurar un proceso eliminado!",
  yes_i_understand: "Si, entiendo",
  cancel: "cancelar",
  showDeletedProcesses: "mostrar procesos eliminados",
  update: "actualizar",
  restore_description: "estás a punto de restaurar un proceso eliminado",
  deleteDescription: "estás a punto de eliminar un proceso",
  requiredFieldsAreMissing: "falta rellenar campos obligatorios",
  errNetwork: "service no disponible",
  err400: "datos inválidos",
  err403: "no tienes acceso",
  err404: "pagina no disponible",
  err409: "conflicto con el registro",
  err500: "error en el servicio",
  err501: "servidor no disponible",
  "Site not Found": "centro no encontrado",
  pending_save: "Pendiente de guardar",
  hours_pending_save_description: "Estás a punto de perder las horas declaradas",
  save: "guardar",
  ignore: "ignorar",
  destroyed_hours: "horas destruidas",
  recovered_hours: "horas recuperadas",
  saved_hours: "horas guardadas",
  recovered_process: "horas recuperadas",
  delete_category_description: "estás a punto de eliminar una categoria",
  delete_site_description: "estás a punto de eliminar un centro",
  deleted_category: "la categoria ha sido eliminada",
  deleted_site: "el centro ha sido eliminado",
  required_field: "campo requirido",
  required_field_category_description: "el campo descripcion requerido",
  required_field_category_name: "el campo nombre requerido",
  required_field_fedid_site_code: "el campo codigo del centro de fedid requerido",
  required_field_site_name: "el campo nombre del centro es requerido",
  required_field_site_code: "el campo código del centro es requerido",
  created_category: "la categoria ha sido creada",
  created_site: "el centro ha sido creado",
  "Activity duration exceding 9 Hours.": "no puede declarar más de 9h en un dia",
  change_your_site: "cambia tu centro",
  change_your_team: "cambia tu equipo",
  change_your_email: "cambia tu email",
  have_api_planning: "Api planning disponible",
  table_is_empty: "La tabla está vacia",
  add_to_drive: "añadir a mi google drive",
  configuration: "config",
  economic: "económico",
  explotation: "explotación",
  account: "cuenta",
  hda: "hda",
  "Total Hours of whole activities is more than 9h.": "total de horas de la actividad no puede ser superior a 9h",
  "bad request": "datos inválidos",
  "page not found": "pagina no encontrada",
  select_date: "seleccionar fecha",
  select_week: "selecciona semana",
  select_team: "selecciona equipo",
  select_collaborator: "selecciona colaborador",
  date_from: "fecha inicial",
  date_to: "fecha final",
  more_options: "más opciones",
  close_dialog: "cerrar el dialogo",
  where_you_have_worked: "donde ha trabajado",
  when_you_have_worked: "cuando has trabajado",
  the_week_what_do_you_search: "la semana que quiere buscar",
  the_team_what_do_you_search: "el equipo que quiere buscar",
  the_collaborator_what_do_you_search: "el colaborador que quiere buscar",
  site_name: "nombre del centro",
  site_code: "código del centro",
  fedid_site_code_openid: "código del centro en openId",
  fedid_site_code: "código del centro",
  site_code_planning: "código del centro en planning",
  category_name: "nombre de la categoria",
  category_description: "descripcion de la categoria",
  user_title: "colaborador",
  total_hours_title: "h. trabajadas",
  total_hours_provider_title: "total h. planning",
  difference_hours_title: "diferencia h.",
  hours_in_team_title: "h. equipo",
  given_polyvalence_title: "poliv. dada",
  received_polyvalence_title: "poliv. recibida",
  process: "proceso",
  polyvalence_team: "equipo poliv.",
  hours: "horas",
  date_title: "fecha",
  name: "nombre",
  planning_code: "código centro en planning",
  fedid_code: "código centro en fedid",
  description: "descripción",
  reset_form: "reset form",
  provider_api: "Conectado con planning",
  no: "no",
  yes: "si",
  your_email_does_not_exist_in_the_app: "tu perfil no existe en la aplicación",
  we_can_create_a_fictitious_profile_in_a_fictitious_center: "puede crear un perfil ficticio en un centro ficticio",
  you_are_about_to_create_a_fictitious_profile_in_a_fictitious_center:
    "estás a punto de crear un perfil ficticio en un centro ficticio",
  the_value_should_between_0_and_9: "la cantidad de horas declaradas debe ser entre 0h y 9h",
  excel: "excel",
  no_content: "no tiene contenido",
  "logistic process": "proceso",
  total_hours: "total horas",
  planning: "planning",
  hours_team: "horas equipo",
  diff: "diferencia",
  given_polyvalence: "poli. dada",
  received_polyvalence: "poli. recibida",
  logistic_process: "proceso",
  dates_error: "error en las fechas",
  date_from_is_later_than_date_to: "la fecha inicial es posterior a la fecha final",
  username: "perfil",
  dni: "dni",
  uuid: "uuid",
  sap_number: "número sap",
  email: "correo",
  select_site: "selecciona centro",
  new_collaborator: "nuevo col.",
  all_fields_are_required: "todos los campos son requeridos",
  field_are_required: "campos requeridos",
  sap_structure_number: "número de sap",
  delete_team_description: "está a punto de eliminar un equipo",
  "deleted/notDeleted": "eliminados o no eliminados",
  total_hda: "total hda",
  total_team: "total equipo",
  ask_support: "ticket soporte it",
  users: "usuarios",
  team_that_you_search: "el equipo que buscas",
  center: "centro",
  mail: "email",
  service: "servicio",
  uid: "perfil",
  branch: "rama",
  username_that_you_search: "perfil que buscas",
  personal_data: "datos personales",
  last_name: "apellido",
  center_data: "data del centro",
  complementary_data: "datos complementarios",
  your_dni: "dni",
  you_are_about_delete_a_user: "estás a punto de eliminar un usuario",
  user: "usuario",
  you_are_about_to_delete_a_team:
    "estás a punto de eliminar un equipo, por favor revisa si el equipo tiene colaboradores dentro si es asi no lo elimines ya que no puedes acceder a ellos posteriormente",
  you_are_about_create_a_planning: "estás a punto de create un planning",
  hour_in: "hora inicio",
  hour_out: "hora final",
  manual: "manual",
  is_manual_user: "es un usuario manual",
  sites: "centros",
  sites_where_has_access: "centros al que tiene acceso",
  select_multi_vada: "seleccione multi vada",
  change_site: "cambia de almacén",
  hour_out_is_smaller_than_hour_in: "la hora de entrada no puede ser igual o menor que hora de salida",
};

export default message;
