import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import hdaModule from "@/modules/hdaModule/router";
import planningModule from "@/modules/planningModule/router";
import useAuth from "@/modules/oauth/composables/useAuth";
import fedidService from "@/modules/oauth/services/FedidService";
import useHda from "@/modules/hdaModule/composables/useHda";
import { ConfirmSwal } from "@/tools";
import i18nConfig from "@/modules/language/configuration/i18nConfig";

const routes: Array<RouteRecordRaw> = [
  {
    ...hdaModule,
  },
  {
    ...planningModule,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  if (from.name === "collaborator") {
    const { thereAreChanges, setChanges } = useHda();
    const { t } = i18nConfig.global;
    if (thereAreChanges.value) {
      ConfirmSwal(t("pending_save"), t("hours_pending_save_description"), "warning", t("ignore"), t("cancel")).then(
        ({ isConfirmed }) => {
          if (isConfirmed) {
            setChanges(false);
            router.push(to);
          }
        }
      );
      return false;
    }
  }
  return true;
});

router.beforeEach((to) => {
  const { getRolesByAppName } = useAuth();
  const roles = getRolesByAppName("HDA");
  const superAdminRole = roles?.find((role) => role.role === "SUPERADMIN");
  const managerRole = roles?.find((role) => role.role === "WAREHOUSEMANAGER");
  const economicRole = roles?.find((role) => role.role === "ECONOMIC");
  switch (to.name) {
    case "configuration":
      if (superAdminRole) return true;
      router.push({ name: "collaborator" });
      break;
    case "explotation":
      if (managerRole || superAdminRole) return true;
      router.push({ name: "collaborator" });
      break;
    case "individualDetails":
      if (managerRole || superAdminRole) return true;
      router.push({ name: "collaborator" });
      break;
    case "economic":
      if (economicRole || superAdminRole) return true;
      router.push({ name: "collaborator" });
      break;
    case "users":
      if (superAdminRole || managerRole) return true;
      router.push({ name: "collaborator" });
      break;
    case "planning":
      if (superAdminRole || managerRole) return true;
      router.push({ name: "collaborator" });
      break;
    default:
      return true;
  }
});

router.beforeEach(() => {
  fedidService.revokeSession();
});

export default router;
