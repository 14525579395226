import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import i18nConfig from './modules/language/configuration/i18nConfig';

import './style.css';
import '@vtmn/icons/dist/vitamix/font/vitamix.css';
import '@vtmn/css';


const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

createApp(App)
    .use(router)
    .use(pinia)
    .use(i18nConfig)
    .mount('#app');
    
