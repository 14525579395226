import { storeToRefs } from 'pinia';
import { useLanguageStore } from '../store/useLanguageStore';
import i18nConfig from '../configuration/i18nConfig';
import { LanguageTypes } from '@/modules/oauth/interfaces/ResponseUserInfo';

const useLanguage = () =>{
    const language = useLanguageStore();
    const {currentLanguage} = storeToRefs(language);
    
    const changeLanguage = (newLanguage:LanguageTypes) =>{

        i18nConfig.global.locale.value = newLanguage;

        language.setCurrentLanguage(newLanguage);
    }

    return{
        currentLanguage,
        
        changeLanguage
    }
}

export default useLanguage;