import { storeToRefs } from "pinia";
import { useAuthStore } from "../store/useAuthStore"

const useAuth = () =>{
    const store = useAuthStore();

    const {
        accessToken,
        initDate,
        lastTokenDate,
        loginState,
        redirectAfterLogin,
        refreshToken,
        userInfo,
    } = storeToRefs(store);

    return {
        accessToken,
        loginState,
        redirectAfterLogin,
        refreshToken,
        initDate,
        userInfo,
        lastTokenDate,

        getRoles: store.getRoles,
        getRolesByAppName: store.getRolesByAppName,
        isTranscurredRefreshTime: store.isTranscurredRefreshTime,

        setAfterLogin: store.setAfterLogin,
        setLoginState: store.setLoginState,
        setAccessToken: store.setAccessToken,
        setRefreshToken: store.setRefreshToken,
        setInitDate: store.setInitDate,
        setUserInfo: store.setUserInfo,
        updateEmail: store.updateEmail,
        updateUuid: store.updateUuid,
        updateSite: store.updateSite,
        revokeSession: store.revokeSession,
        setLastTokenDate: store.setLastTokenDate
    };
}

export default useAuth;