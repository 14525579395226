import { defineStore } from "pinia";
import { LanguageState } from "../interfaces/LanguageState";

export const useLanguageStore = defineStore('languageStore',{
    state: (): LanguageState => ({
        currentLanguage: undefined,
    }),
    getters:{
        
    },
    actions:{
        setCurrentLanguage(currentLanguage:string){
            this.currentLanguage = currentLanguage;
        }
    },
    persist: true
})