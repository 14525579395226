import i18nConfig from "@/modules/language/configuration/i18nConfig";
import fedidService from '@/modules/oauth/services/FedidService'

const { t } = i18nConfig.global

interface Error {
    code?: string,
    response: {
        status: number,
        data: {
            message: string,
            detail: string
        }
    }
}

const checkErrorsApiResponse = (error: Error): { icon: "error" | "success", title: string, text?: string } => {    
    if (error.code === 'ERR_NETWORK') {
        return {
            icon: 'error',
            title: t('errNetwork')
        }
    }

    if (error.response?.status === 401) {
        fedidService.revokeSession();
        fedidService.refreshTokenAndSaveData(false).then(() => {
            window.location.reload();
        });
    }

    if (error.response?.status === 403) {
        return {
            icon: 'error',
            title: t('err403')
        }
    }

    if (error.response?.status === 404) {
        return {
            icon: 'error',
            title: t('err404'),
            text: t(error.response.data.detail)
        }
    }

    if (error.response?.status === 400) {
        return {
            icon: 'error',
            title: t('err400'),
            text: t(error.response.data.message)
        }
    }

    if (error.response?.status === 409) {
        return {
            icon: 'error',
            title: t('err409')
        }
    }

    if (error.response?.status === 500) {
        return {
            icon: 'error',
            title: t('err500'),
            text: 'error_500'
        }
    }

    if (error.response?.status > 500) {
        return {
            icon: 'error',
            title: t('err501')
        }
    }

    return {
        icon: 'success',
        title: 'ok'
    }
}

export default checkErrorsApiResponse;